import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import FancyUnderline from "../components/fancy-underline"
import { Link } from "gatsby"
import { FormattedDate, FormattedMessage } from "react-intl"

const TitleSection = ({ title }) => {
  return (
    <section className="container mx-auto mt-4 px-5 text-center">
      <div className="inline-block relative pb-2">
        <p className="text-4xl font-extralight">{title}</p>
        <FancyUnderline />
      </div>
    </section>
  )
}

TitleSection.defaultProps = {
  title: "",
}

TitleSection.propTypes = {
  title: PropTypes.string,
}

const NewsListSection = ({ edges }) => {
  return !edges ? null : (
    <section className="container mx-auto space-y-4 my-8 px-5 lg:w-3/4">
      {edges.map(
        (
          {
            node: {
              frontmatter,
              excerpt,
              fields: { slug },
            },
          },
          index
        ) => (
          <div key={frontmatter.title + index}>
            <h1 className="mb-2 transition hover:opacity-75">
              <Link to={slug || "/"}>{frontmatter.title || ""}</Link>
            </h1>
            <p className="font-light">
              {!!frontmatter.date && (
                <>
                  <FormattedDate value={frontmatter.date} dateStyle="long" />
                  <span className="mx-2">|</span>
                </>
              )}
              {frontmatter.author.name || ""}
            </p>
            <p>{excerpt || ""}</p>
            <hr className="border border-black mt-4 mb-2" />
            <Link
              to={slug || "/"}
              className="inline-flex items-center btn-secondary pl-0"
            >
              <FormattedMessage id="readMore" defaultMessage="Read more" />
              <FontAwesomeIcon className="ml-1" icon={faChevronRight} />
            </Link>
          </div>
        )
      )}
    </section>
  )
}

NewsListSection.propTypes = {
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          date: PropTypes.string,
          author: PropTypes.shape({ name: PropTypes.string }),
        }),
        excerpt: PropTypes.string,
        fields: PropTypes.shape({
          slug: PropTypes.string,
        }),
      }),
    })
  ),
}

export const NewsListPageTemplate = ({ edges }) => {
  return (
    <>
      <TitleSection title="News" />
      <NewsListSection edges={edges} />
    </>
  )
}

NewsListPageTemplate.propTypes = {
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          date: PropTypes.string,
          author: PropTypes.shape({ name: PropTypes.string }),
        }),
        excerpt: PropTypes.string,
        fields: PropTypes.shape({
          slug: PropTypes.string,
        }),
      }),
    })
  ),
}

const NewsListPage = ({ edges }) => {
  const description =
    edges[0]?.node?.frontmatter?.description || "WELL PLUS TRADE GmbH News"
  return (
    <Layout>
      <Seo title="News" description={description} />
      <NewsListPageTemplate edges={edges} />
    </Layout>
  )
}

export default NewsListPage
